<template>
  <div class="main-detail">
    <Header :curActive="2"></Header>
    
    <div class="main mat">
      <div class="location">
        您当前位置：
        <span>首页 &gt; 2019年可持续发展论坛</span>
      </div>
      <div class="logo_new">
        <img src="./static/picture/logo_new.jpg" class="logo_box" />
      </div>
      <div class="cv_box">
        <div class="fl cv_img">
          <img src="./static/picture/33464f71ec5748b98450a86602cfd694.gif" alt="简介" title="简介" />
        </div>
        <div class="cv_text" v-html="categoryDescrip" style="font-size: 16px;font-family: 仿宋_gb2312;"></div>
        <div class="clear"></div>
      </div>
      <div class="yc_box w555 fl">
        <div class="title1e">
          <span class="title2e">贺信</span>
        </div>

        <div class="cg_box_new w555" id="hotpic1">
          <div id="NewsPic1">
            <div class="fcon" style="display:block">
                <a 
                    v-for="(item,i) in dataOne" :key="i"
                    :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id"
                    class="fl w267 p15"
                    target="_blank"
                    :title="item.contentTitle ? item.contentTitle : ''"
                >
                    <div class="fl hdtextbox">
                    <h2
                        style="font-size: 18px;font-weight:500; margin-top: 20px; margin-left: 10px;"
                    >{{item.contentTitle ? item.contentTitle : ''}}</h2>
                    </div>
                    <div class="clear"></div>
                </a>
                <div class="clear"></div>
            </div>
          </div>
          <div class="jumpBtn D1fBt" style="display:none;">
            <ul>
              <li jumpimg="1" style="background-color:  #519ad7;">
                <a href="javascript:void(0)"></a>
              </li>
            </ul>
          </div>
          <div class="plus"></div>
          <div class="minus"></div>
        </div>
        <div class="title1e mt20">
          <span class="title2e">2019年可持续发展论坛</span>
        </div>

        <a href="/ms/file/getimage/681" target="_blank" class="w555 btn_yc ycimg1">
          <div class="yctext w491">
            <span>2019年可持续发展论坛议程</span>
          </div>
        </a>
        <div class="w555">
          <a href="/ms/file/getimage/662" target="_blank" class="w267 btn_yc ycimg2 fl">
            <div class="yctext w206">
              <span>可持续发展企业行动倡议</span>
            </div>
          </a>
          <a href="/ms/file/getimage/665" target="_blank" class="w267 btn_yc ycimg3 fr">
            <div class="yctext w206">
              <span>大会倡议</span>
            </div>
          </a>
          <div class="clear"></div>
        </div>
      </div>

        <div class="yc_box w555 fr">
            <div class="title1e">
                <span class="title2e">论坛嘉宾及观点集粹</span>
            </div>
            <div class="fybj_box">
                <div class="fytitle">
                    <div class="fybj_img fl">
                        <span>发言代表</span>
                    </div>
                    <span class="fy_more fr">
                        <a href="javascript:void(0);" id="ResumeMore" @click="showalertBox">更多+</a>
                    </span>
                </div>

                <div class="clear"></div>
                <div class="jb_cj">
                    <a 
                        v-for="(item,i) in dataTwo" :key="i"
                        :href="'/ms/file/getimage/' + item.contentFile.split(',')[1].split(':')[1].substring(1,item.contentFile.split(',')[1].split(':')[1].length-1)"
                        target="_blank"
                    >{{item.contentTitle ? item.contentTitle : ''}}</a>
                    
                </div>

                <div class="viewpoint">
                    <div class="viewpoint_img fl">
                        <span>观点</span>
                    </div>
                    <span class="viewpoint_btn fr">
                        <input type="hidden" value="1" id="viewPageNum" />
                        <input type="hidden" value="8" id="viewTotal" />
                        <a href="javascript:void(0)" @click="arrowClick3('left')">
                            <img src="./static/picture/leftf.png" />
                        </a>
                        <a href="javascript:void(0)" @click="arrowClick3('right')">
                            <img src="./static/picture/rightf.png" />
                        </a>
                    </span>
                </div>
                <div class="clear"></div>
                <div id="divViewPoint">
                    <el-carousel :interval="5100" arrow="never" :indicator-position="'none'" ref="cardshow3">
                        <el-carousel-item v-for="(item,i) in dataThree" :key="i">
                            <ul class="viewpoint_text" style="display:block">
                                <li v-for="(sitem,j) in item" :key="j">
                                    <a
                                    :href="sitem.contentIsChain == '1' ? sitem.contentChain : '/detail?docId='+sitem.id"
                                    target="_blank"
                                    >{{sitem.contentTitle ? sitem.contentTitle : ''}}</a>
                                </li>
                            </ul>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
      </div>

      <div class="clear"></div>

      <div class="media_box" id="picture_box2" style="padding-bottom:0">
        <div class="title1e yc_box">
          <span class="title2e">媒体报道</span>
        </div>
        <div class="pic-slide-box" id="pic-slide-box2">
            <el-carousel :interval="5000" arrow="never" :indicator-position="'none'" ref="cardshow2">
                <el-carousel-item v-for="(item,i) in dataFour" :key="i">
                    <div>
                        <div class="fl media_text w555" v-for="(sitem,j) in item" :key="j">
                            <a
                                :href="sitem.contentIsChain == '1' ? sitem.contentChain : '/detail?docId='+sitem.id"
                                target="_blank"
                            >
                                <h2>{{sitem.contentTitle ? sitem.contentTitle : ''}}</h2>
                                <p class="twolines">{{sitem.contentDescription ? sitem.contentDescription : ''}}</p>
                            </a>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="clear"></div>
        <div class="switch_box" style="position:absolute;width:1160px;">
          <div class="switchleft" style="top:-190px" @click="arrowClick2('left')"></div>
          <div class="switchright" style="top:-190px" @click="arrowClick2('right')"></div>
        </div>
        <div class="clear"></div>
      </div>

      <div class="picture_box" id="picture_box1" style="padding-bottom:0">
        <div class="title1e yc_box">
          <span class="title2e">图片集锦</span>
        </div>
        <div class="pic-slide-box" id="pic-slide-box1" style="height:170px;">

            <el-carousel :interval="4000" arrow="never" :indicator-position="'none'" ref="cardshow">
                <el-carousel-item v-for="(item,i) in dataFive" :key="i">
                    <div style="min-height:165px">
                        <ul>
                            <li class="picture_li" v-for="(sitem,j) in item" :key="j">
                                <a :href="sitem.contentIsChain == '1' ? sitem.contentChain : '/detail?docId='+sitem.id" target="_blank">
                                    <img :src="(sitem.imgSrc && sitem.imgSrc.length > 0) ? imgBase + sitem.imgSrc[0] : defaultImg1" />
                                </a>
                            </li>
                            
                        </ul>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="clear"></div>
        <div class="switch_box">
          <div class="switchleft" @click="arrowClick('left')"></div>
          <div class="switchright" @click="arrowClick('right')"></div>
        </div>
        <div class="clear"></div>
      </div>

        <el-dialog
            title="简历"
            :visible.sync="dialogVisible"
            width="35%"
            height="40%"
        >
            <div class="fytitle">
                <div class="fybj_img fl">
                    <span>发言代表</span>
                </div>
                <span class="fy_more fr">
                    <a href></a>
                </span>
            </div>
            <div class="clear"></div>
            <div style="width:555px; padding:20px 0; color:#acacac;">
                <a 
                    v-for="(item,i) in dataTwo" :key="i"
                    :href="'/ms/file/getimage/' + item.contentFile.split(',')[1].split(':')[1].substring(1,item.contentFile.split(',')[1].split(':')[1].length-1)"
                    target="_blank"
                    style="padding:0px 20px; font-size:16px; color:#333; white-space:nowrap;border-right: 1px solid #000;"
                >{{item.contentTitle ? item.contentTitle : ''}}</a>
            </div>
        </el-dialog>
    </div>
    <Footer></Footer>
    <!-- 回到顶部 -->
    <el-backtop target=".main-detail" :bottom="100">
        <!-- <div
            style="{
                height: 100%;
                width: 100%;
                background-color: #f2f5f6;
                box-shadow: 0 0 6px rgba(0,0,0, .12);
                text-align: center;
                line-height: 40px;
                color: #1989fa;
            }"
        >
            UP
        </div> -->
        <i class="el-icon-caret-top"></i>
    </el-backtop>
  </div>
</template>

<script>
import $ from "jquery";
import api from "@/../static/api/api.js";

import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";

import defaultImg1 from "@/assets/imgs/default.png";
import defaultImg2 from "@/assets/imgs/default2.png";
import defaultImg3 from "@/assets/imgs/default3.png";

export default {
  name: "communicate",
  data() {
    return {
      imgBase: this.$store.state.imgBase,
      defaultImg1: defaultImg1, //轮播图默认图片
      defaultImg2: defaultImg2, //研究项目默认图
      defaultImg3: defaultImg3, //交流传播默认图
      dialogVisible: false,     //弹框是否显示
      menuList: [],//栏目数据
      categoryDescrip: '',      //栏目简介
      dataOne: [],              //贺信
      dataTwo: [],              //简历
      dataThree: [],            //观点
      dataFour: [],             //媒体报道
      dataFive: [],             //图片集锦
      curtitle: ''
    };
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    document.title = '交流传播 | 知识中心'
    this.fetchData();
    this.beforeJsCode();
    this.fetchDataOne();
    this.fetchDataTwo();
    this.fetchDataThree();
    this.fetchDataFour();
    this.fetchDataFive();
  },
  methods: {
    fetchData() {
        let _this = this;

        let menuId1 = '1470628816435646466';
        let onlyChild1 = true;
        let paltformId1 = '';
        api.getTopMenuList(menuId1, onlyChild1, paltformId1).then(res => {
            if (res.data) {
                _this.categoryDescrip = res.data.categoryDescrip.replace(/\n/g, "<br/>");;
                _this.menuList = res.data.childrenList; 
                console.log(_this.menuList,'_this.menuList')
            }
        });
    },
    //贺信
    fetchDataOne(){
        let _this = this;
        let menuId = '1470647379875594242';//贺信
        let pageNum = 1;
        let pageSize = 2;
        api.getArticleList(menuId, pageNum, pageSize).then(res => {
            if (res.data) {
                _this.dataOne = res.data.list;
                _this.curtitle = res.data.title;
            }
        });
    },
    //简历
    fetchDataTwo(){
        let _this = this;
        let menuId = '1470647999890194433';
        let pageNum = 1;
        let pageSize = 20;
        api.getArticleList(menuId, pageNum, pageSize).then(res => {
            if (res.data) {
                _this.dataTwo = res.data.list;
                console.log(_this.dataTwo[0].contentFile.split(',')[1].split(':')[1],'contentFile')
                _this.curtitle = res.data.title;
            }
        });
    },
    //观点
    fetchDataThree(){
        let _this = this;
        let menuId = '1470647125977595906';
        let pageNum = 1;
        let pageSize = 50;
        api.getArticleList(menuId, pageNum, pageSize).then(res => {
            if (res.data) {
                let tmpData = res.data.list;
                _this.curtitle = res.data.title;
                _this.dataThree = [];
                let tmpArr = [];
                if(tmpData.length > 0){
                    for(let i=0;i<tmpData.length;i++){
                        if(i%5 == 4 || i == (tmpData.length-1)){
                            tmpArr.push(tmpData[i])
                            _this.dataThree.push(tmpArr);
                            tmpArr = [];
                        }else{
                            tmpArr.push(tmpData[i])
                        }
                    }
                }
            }
        });
    },
    //媒体报道
    fetchDataFour(){
        let _this = this;
        let menuId = '1470646923141054465';
        let pageNum = 1;
        let pageSize = 50;
        api.getArticleList(menuId, pageNum, pageSize).then(res => {
            if (res.data) {
                let tmpData = res.data.list;
                _this.curtitle = res.data.title;
                _this.dataFour = [];
                let tmpArr = [];
                if(tmpData.length > 0){
                    for(let i=0;i<tmpData.length;i++){
                        if(i%5 == 4 || i == (tmpData.length-1)){
                            tmpArr.push(tmpData[i])
                            _this.dataFour.push(tmpArr);
                            tmpArr = [];
                        }else{
                            tmpArr.push(tmpData[i])
                        }
                    }
                }
            }
        });
    },
    //图片集锦
    fetchDataFive(){
        let _this = this;
        let menuId = '1481155429890265090';
        let pageNum = 1;
        let pageSize = 50;
        api.getArticleList(menuId, pageNum, pageSize).then(res => {
            if (res.data) {
                let tmpData = res.data.list;
                _this.curtitle = res.data.title;
                _this.dataFive = [];
                let tmpArr = [];
                if(tmpData.length > 0){
                    for(let i=0;i<tmpData.length;i++){
                        if(i%5 == 3 || i == (tmpData.length-1)){
                            tmpArr.push(tmpData[i])
                            _this.dataFive.push(tmpArr);
                            tmpArr = [];
                        }else{
                            tmpArr.push(tmpData[i])
                        }
                    }
                    console.log(_this.dataFive,'_this.dataFive')
                }
            }
        });
    },
    pageToFn(id){
        window.location.href = window.normalUrl + '/ms/file/getimage/' + id;
    },
    //获取URL参数
    getQueryString(key) {
        var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
        var result = window.location.search.substr(1).match(reg);
        return result ? decodeURIComponent(result[2]) : null;
    },
    //点击观点的轮播图左右按钮
    arrowClick3(val){
        if(val == 'right'){
            this.$refs.cardshow3.next();
        }else{
            this.$refs.cardshow3.prev();
        }
    },
    //点击媒体报道的轮播图左右按钮
    arrowClick2(val){
        if(val == 'right'){
            this.$refs.cardshow2.next();
        }else{
            this.$refs.cardshow2.prev();
        }
    },
    //点击图片集锦的轮播图左右按钮
    arrowClick(val){
        if(val == 'right'){
            this.$refs.cardshow.next();
        }else{
            this.$refs.cardshow.prev();
        }
    },
    //点击发言代表的更多按钮弹出弹出框
    showalertBox(){
        this.dialogVisible = true;
    },
    beforeJsCode(){
        $('.closeFloatl0').click(function () { $('#adl0').hide(); })

        
    }
  }
};
</script>

<style scoped>
.main-detail {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: left;
  overflow-x:hidden;
}
.focus-title {
    float: left;
    color: #fff;
    padding-left: 10px;
    margin: 0;
    width: 450px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.twolines{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.f16 {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
}

.news-sublen {
    width: 460px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/* CSS Document */
body,th,td,input,select,textarea,div,p{padding:0; margin:0;}
body{font:14px "microsoft yahei","Arial Narrow",HELVETICA;-webkit-text-size-adjust:100%;color:#000; line-height:160%;}
dl,ol,dt,dd,ul,li,form,h1,h2,h3,h4,h5,h6{margin:0; padding:0;list-style:none;font-weight:normal;}
img{border:0; vertical-align:middle;display:inline-block;}
a{text-decoration:none;}
a:link{text-decoration:none;}
a:visited{text-decoration:none;}
a:hover{text-decoration:none;color:#0855a5}

/* font */
.fB {font-weight:bold}
.txtCtr {text-align:center}
/* color */
.red1{ color:#aa2d28;}
.hs_col{ color:#333}
.blue1{color: #f72222;}
/* float */
.fl {float:left;display:inline;position:relative}
.fr {float:right;display:inline}
.clear {clear:both;display:block}
.ml20{ margin-left:20px;}
.ml30{ margin-left:35px;}
.mt30{ margin-top:40px;}

body{ background:#fff}
.main{ width:1160px; margin:0 auto; padding:0 10px }
.mainfff{background-color:#fff;width:1160px; margin:0 auto; padding:0 10px }
.topbj{ background:url(./static/images/topbj.png) repeat-x; height:135px;min-width:1180px;}
.header{ height:135px; position:relative;/* background:url(./static/images/imgbghot.png) no-repeat center right*/}
.logo{ background:url(./static/images/logo.png) no-repeat; height:80px; width:516px; position:absolute;bottom:30px;}

.yuncai{ background:url(./static/images/yuncai.png) no-repeat bottom;}
.switch .on{background-color:#969696;}

/*导航*/
.nav{ height:45px; background:#1877c9; width:100%;min-width:1180px;}
.nav ul li{ float:left;padding:0;margin:0}
.link-scaleupbounce {background:#1877c9;}
.link-scaleupbounce a::before{position: absolute;top: 0;z-index: -1;box-sizing: content-box;width: 100%;height: 100%;background-color: #095795;content: '';opacity: 0;-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;transition: transform 0.2s, opacity 0.2s;-webkit-transition-timing-function: cubic-bezier(0.25,0.25,0.325,1.39);transition-timing-function: cubic-bezier(0.25,0.25,0.325,1.39);-webkit-transform: scale(0);transform: scale(0);}
.link-scaleupbounce a:hover::before,.link-scaleupbounce a:focus::before {height: 45px;opacity: 1;-webkit-transform: scale(1);transform: scale(1);}
.section {position: relative;line-height: 45px;z-index: 1;/*overflow: hidden;*/-webkit-backface-visibility: hidden;backface-visibility: hidden;}
.section a {position: relative;display: inline-block;outline: none;color: #fff;vertical-align: bottom;text-decoration: none;white-space: nowrap;}
.section span{ padding: 0 .9em; font-size:17px} 
.section .on{background-color:#095795;height: 45px;}
.mm_two{position:absolute; z-index:100;top:45px;display:none;background-color:#1877c9;}
.mm_two .mm_two_name{position:relative;border-top:1px solid #095795;}
.mm_two .mm_two_name a{width:100%;}

.title2{ background:url(./static/images/tit1.jpg) no-repeat; height:50px;}
.title3{ background:url(./static/images/tit2.jpg) no-repeat; height:50px;}
.title4{ background:url(./static/images/tit3.jpg) no-repeat; height:50px;}
.title5{ background:url(./static/images/tit4.jpg) no-repeat; height:50px;}
/*<!--
.drc_zt{ background:url(./static/images/bg_bigbg.png) no-repeat center;}-->*/

.zySearch{ width:430px;position: relative;position: absolute;bottom: 30px;right: 0;text-align: right}
.search-input {
    color: #999;
    border: 1px solid #D0D0D0;
    height: 33px;
    line-height: 33px;
    width: 310px;
    padding: 0 10px;
}
.zySearch .search-btn {
    background: none repeat scroll 0 0 #1877c9;
    border: 0 none;
    border-radius: 0;
    color: #FFFFFF;
    cursor: pointer;
    height: 35px;
    line-height: 33px;
    padding: 0;
    width: 85px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    display: inline-block;
    position: relative;
}
.zySearch .en{ color:#1877c9; font-size:16px;padding-bottom:5px; font-family:Arial, Helvetica, sans-serif}



.zpbox{ height:420px; background-color:#eeeeee; margin-bottom:70px; margin-top:40px;}
.zpbox .bjimg{ background:url(./static/images/bookbj.png) no-repeat; width:1164px; height:338px; margin:0 auto; position:relative;top:46px; }
.zpbox .bjimg ul{padding-top: 74px;  width:460px ;text-overflow : ellipsis;white-space : nowrap;overflow : hidden; }
.zpbox .bjimg li a{ color:#333; font-size:16px; line-height:220% }
.zpbox .bjimg li a:hover{color:#015293;}
.leftbox{padding-left:60px;}
.rightbox{padding-right:60px;}

.zfgzbg_m_left{ width:630px;height:370px;background: url(./static/images/bj1.jpg) no-repeat 0px 0px;padding: 12px 0px 0px 15px; } 
.focus {position: relative;width:630px;height:370px;}
.focus .fPic {position: absolute;left: 0px;top: 0px;}
.focus .fcon {position: relative;width: 100%;float: left;}
.focus img {width:630px;height:370px;}
.focus .fbg {right: 40px;position: absolute;height: 50px;text-align: center;z-index: 200;}
.focus .shadow {width: 100%;position: absolute;bottom: 0;left: 0px;z-index: 10;height: 40px;line-height: 40px;background: rgba(0,0,0,0.6);filter: progid:DXImageTransform.Microsoft.gradient( GradientType = 0,startColorstr = '#80000000',endColorstr = '#80000000')\9;display: block;text-align: left;}
.focus .fbg div {margin: 17px auto 0;overflow: hidden;zoom: 1;height: 14px;position: absolute;right:25px;}
.focus .D1fBt a {position: relative;display: inline;width: 11px;height: 11px;border-radius: 7px;margin: 0 5px;color: #B0B0B0;font: 12px/15px "\5B8B\4F53";text-decoration: none;text-align: center;outline: 0;float: left;background: #D9D9D9;}
.focus .D1fBt .current {background: #1f8bcc;}
.focus .D1fBt i {display: none;font-style: normal;}

.focus .minus,.focus .plus{position:absolute;top:110px; width:30px; height:75px; background:url(./static/images/focus_arrow.png) no-repeat 0 0; text-align:center;cursor:pointer;}
.focus .minus{left:0;background-position:0 0;}
.focus .plus{right:0;background-position:0px -75px;}
.focus .minus:hover{background-position:-30px 0px;}
.focus .plus:hover{background-position:-30px -75px;}

.zfgzbg_m_right{ width:485px;}
.zfgzbg_m_right .hottitle{ background:url(./static/images/line.png) no-repeat bottom; padding:18px 0; }
.zfgzbg_m_right .hottitle h2{font-size: 20px; color:#000!important}
.zfgzbg_m_right .hottitle p a{ color:#8e8e8e}
.zfgzbg_m_right span a{ background:url(./static/images/iconhot.png) no-repeat left; padding-left:16px;}
.zfgzbg_m_right li a{ color:#333; text-decoration:none}
.zfgzbg_m_right li a:hover{ color:#1877c9}
.zfgzbg_m_right li span{ font-size:16px;line-height: 30px}
.zfgzbg_m_right li p{ color:#8e8e8e; padding-top:8px;}
.more{position: relative;bottom: -10px;color: #999;}
.more:hover{position: relative;bottom: -10px;color: #1877c9; text-decoration:none}

.zhuanti{ width:750px;position: relative;}
.zhuanti .ztnew .img {width: 330px;height: 200px;overflow: hidden;position: relative;}
.zhuanti .ztnew .img img{cursor: pointer;  transition: all 0.6s;  }
.zhuanti .ztnew .img img:hover{  transform: scale(1.2);}  

.zhuanti .zttext{ height:230px;width:330px;box-sizing:border-box;-moz-box-sizing:border-box; /* Firefox */-webkit-box-sizing:border-box; /* Safari */padding:30px; background-color:#FFF; border:1px solid #d5d5d5; }
.zhuanti .zttext h2{ font-size:20px; line-height:28px; padding-bottom:10px}
.zhuanti .zttext p a{ color:#666666; font-size:15px;line-height:25px; text-align:justify}
.newimgp{ background:url(./static/images/new.png) no-repeat; width:47px; height:51px; position:absolute; top:-5px; right:15px;}

/*.ztdongt{ width:860px;  height:430px;margin: 35px 0;}*/


.five_lb{ position:relative; height:280px;margin-bottom: 70px;margin-top:50px;}
.but_left {width: 37px;height: 61px;position: absolute;top: 100px;left: 0px;}
.but_right {width: 37px;height: 61px;position: absolute;top: 100px;right: 0px;}
.zjfive{ width:1138px;margin: 0 auto;overflow: hidden;  }
.mobile li { width:226px; float:left}
.mobile li a{ display:block; width:150px;margin: 0 auto; color:#333; text-decoration:none}
.mobile li a:hover{ color:#2973bd}
.mobile li a dd{ width:142px; text-align:center; font-size:20px; padding:15px 0; }
.mobile li a p{ font-size:14px;}
.mobile li img{ width:142px; height:142px; border:1px solid #e7e7e7; border-radius: 100px;}

.link{ margin: 10px auto;padding-bottom: 10px;text-align:center;width: 1050px; }
.link a{ display:block; width:175px; float:left;}

.footer{ background:#1877c9 url(./static/images/footer-bg.jpg) no-repeat bottom; height:180px; color:#cbe1f9; width:100%; min-width:1180px;line-height:30px; font-size:13px;}
.footer .nav2{ margin:0 auto; width:700px; padding-top:40px;}
.footer a{ color:#cbe1f9}
.icp{display:inline-block;text-decoration:none;height:20px;line-height:20px;background:url(./static/images/icp.png) no-repeat center left;padding-left:22px;}
.frbox{width: 380px;padding-top: 40px; }
.frbox a{ float:left;margin-left: 15px;}
.flbox{width: 700px;}
.bot-twitter{ background:url(./static/images/twitter.png) no-repeat center; width:36px; height:36px;display: block;}
.bot-twitter:hover{ background:url(./static/images/twitter2.png) no-repeat center; width:36px; height:36px;display: block;}
.bot-Facebook{ background:url(./static/images/facebook.png) no-repeat center;width:36px; height:36px;display: block;}
.bot-Facebook:hover{background:url(./static/images/facebook2.png) no-repeat center; width:36px; height:36px;display: block;}
.bot-linkedIn{background:url(./static/images/linkedin.png) no-repeat center; width:36px; height:36px;display: block;}
.bot-linkedIn:hover{background:url(./static/images/linkedin2.png) no-repeat center; width:36px; height:36px;display: block;}
.bot-weixin{ background:url(./static/images/weixin.png) no-repeat center;width:36px; height:36px;display: block;}
.bot-weixin:hover{ background:url(./static/images/weixin2.png) no-repeat center;width:36px; height:36px;display: block;}
.bot-weibo{ background:url(./static/images/weibo.png) no-repeat center;width:36px; height:36px;display: block;}
.bot-weibo:hover{ background:url(./static/images/weibo2.png) no-repeat center;width:36px; height:36px;display: block;}
/*微信二维码*/
.wechat-qr-code-img { position: fixed;bottom: 130px;z-index:500;width: 10rem;margin-bottom: 1rem;border: 0 solid #fff;-webkit-transform: translateX(-50%);transform: translateX(-50%);box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);display: none;}
.bot-weixin:hover .wechat-qr-code-img{display:block;}
.bot-weixin:after .wechat-qr-code-img{display:none;}
/*微博二维码*/
.weibo-qr-code-img { position: fixed;bottom: 130px;z-index:500;width: 13rem;margin-bottom: 1rem;border: .5rem solid #fff;-webkit-transform: translateX(-50%);transform: translateX(-50%);box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);display: none;}
.bot-weibo:hover .weibo-qr-code-img{display:block;}
.bot-weibo:after .weibo-qr-code-img{display:none;}



/*----------------二级页面---------------------*/
.home{ background:url(./static/images/imgleft.png) no-repeat;}
.mat{ margin-top:50px;}
.ma_left{ width:250px; box-sizing:border-box;-moz-box-sizing:border-box; /* Firefox */-webkit-box-sizing:border-box; /* Safari */}
.ma_leftimg,.leftimg-169{ background:url(./static/images/ywimg.jpg) no-repeat top; width:250px;}
.leftimg-218{ background:url(./static/images/bkimg.jpg) no-repeat top; width:250px;}
.ma_leftimg_e,.leftimg-171{ background:url(./static/images/e_ywimg.jpg) no-repeat top; width:250px;}
.titlef{ height:37px; background-color:#1877c9;text-align:center; line-height:34px;} 
.titlef span{ color:#fff; font-size:20px;background:url(./static/images/imgicon.png) no-repeat ; width:15px; height:17px;display: inline;padding-left: 30px;}
.left-nav {margin-top: 90px;background: #f5f8fd;padding: 20px 0 25px;}
.left-nav li {position: relative;margin-bottom: 8px;line-height: 27px;font-size: 15px;}
.left-nav li h3{ color:#616b74;margin-bottom: 10px;}
.left-nav li .acss{color:#616b74; display:block; padding-left:30px; padding-right:20px; font-family:Arial, Helvetica, sans-serif;}
.left-nav .or {color: #fff !important;background-color: #e7eff8 !important; font-size:14px;; text-align:center; }
.tl{ background-position:0 6px; width:21px; height:21px;padding-top: 5px;}
.t2{ background-position:0 -26px; width:21px; height:21px;padding-top: 5px;}
.t3{ background-position:0 -62px; width:21px; height:21px;padding-top: 5px;}
.t4{ background-position:0 -100px; width:21px; height:21px;padding-top: 5px;}
.left-nav i{width:21px; display: block;float: left; padding-right:10px;}

.ma_right{ width:870px;box-sizing:border-box;-moz-box-sizing:border-box; /* Firefox */-webkit-box-sizing:border-box; /* Safari */ }
.location {
    background: url(./static/images/icon_li.png) no-repeat scroll 9px 8px #f3f5f8; 
    color:#616b74;
    font-size: 14px;
    line-height: 37px;
    padding-left: 30px;
    text-align: left;
}
.textb1 .article img{ width:164px; height:100px}
.textb1 .article{ padding-top:40px;border-bottom: dotted 1px #CCC; padding-bottom:40px; }
.textb1 .article .text{width:690px;}
.textb1 .article .text h3{ font-size:17px;}
.textb1 .article .text span{ color:#666; font-size:13px;}
.textb1 .article .text p{ text-align:justify; color:#7d7d7d; padding-top:5px;}


.y-pagination {float: right;zoom: 1;font-family: "microsoft yahei";font-size: 12px; margin:30px 0;padding-bottom: 20px;}
.y-pagination .p-prev,.y-pagination .p-next,.y-pagination .p-end{width: 66px;}
.y-pagination a.p-item:hover, .y-pagination .p-prev:hover, .y-pagination .p-next:hover, .y-pagination .p-first:hover, .y-pagination .p-last:hover {border-color: #3188df;background-color: #3188df;text-decoration: none;
color: #fff;}
.y-pagination .p-elem {display: inline-block;margin: 0 4px;vertical-align: middle;text-align: center;min-width: 30px;height: 30px;line-height: 30px;_width: expression(this.width > 30 ? "30px" :"");}
.y-pagination a.p-item, .y-pagination .p-prev, .y-pagination .p-next, .y-pagination .p-first, .y-pagination .p-last,.y-pagination .p-end{border: solid 1px #ddd;color: #333;border-radius: 2px;text-decoration: none;}
.y-pagination .p-total, .y-pagination .p-item-go {color: #666;}
.y-pagination .p-ipt-go {width: 40px;border: solid 1px #ddd;}
.y-pagination .p-ipt-go, .y-pagination .p-btn-go {height: 22px;line-height: 22px;vertical-align: top;padding: 0;margin: 3px 5px 0;text-align: center;}
.y-pagination .p-btn-go {display: inline-block;color: #3188df;width: 38px;border: solid 1px #3188df;text-decoration: none;border-radius: 2px;}

.y-pagination .current {border-color: #3188df;background-color: #3188df;color: #fff;cursor: default;}
.nLi .arrow {position: absolute;top: 0;right: 10px;width: 13px;height: 13px;font-family: "宋体";}


/*新增*/
/*.rdztbox{ margin-bottom:60px;}*/
.special_boxcon{ width:360px; margin-top:30px}
.special_boxcon img{ width:145px; height:85px; border-radius:3px;}
.special_text{width: 200px;}
.special_text h3{ font-size:16px; padding-bottom:8px; color:#333;line-height: 135%;}
.special_text a{ color:#666666}
.special_text a:hover,.special_text h3:hover{color:#1877c9; text-decoration:none}
.special_text span{ color:#989898}


.hot_on{transition:background 0.1s;}
.hot_on:hover{ background-color:#4492d5}
.hot_on:hover h3 span{ background-color:#fff!important;  color:#2973bd!important }
.hot_on:hover  a{ color:#fff!important;}

.acss2{ text-align:center;display: block; color:#616b74; width:250px;}
.acss2:hover{ background-color:#dddddd; text-decoration:none; color:#333}
.left-nav .on1{ background-color:#549fe0; color:#fff}
.gy-text{ padding:40px 0}
.gy-text p{ text-indent:2em;line-height: 26px;margin-top: 16px;font-size: 15px; font-family:"宋体"; text-align:justify}
.gy-text h2{ text-indent:2em; font-family:"楷体"; font-size:21px; line-height:30px; color:#000; text-align:justify}
.fontf{ font-family:"楷体"; text-align:right; font-size:21px;line-height: 26px;margin-top: 12px;display: block; }
.cssys{font-size: 21px!important;font-family: '微软雅黑'!important;border-bottom: 1px dashed #ddd;padding-bottom: 10px; padding-left:28px; margin-top:50px;}
#portamento_container #sidebar.fixed {position: fixed;margin-top: 40px;}




.fzd_text{ background-color:#fafafa; padding:40px; margin-bottom:60px;}
.fzd_text h3{font-size: 26px;line-height: 40px; text-align:center; color:#333; }
.fzd_text p{ text-indent:2em;line-height: 28px;margin-top: 20px;font-size: 16px; font-family:"宋体"; text-align:justify}
.fzd_text span{color:#666; font-size:14px; text-align:center;border-bottom:1px dotted #ccc;padding-bottom:40px; margin-bottom:50px; display:block; padding-top:10px;}
.location1{ color:#666}



.special_boxcon01{ width:400px;box-sizing:border-box;-moz-box-sizing:border-box; /* Firefox */-webkit-box-sizing:border-box; /* Safari */margin-bottom:40px;}
.special_boxcon01 img{ width:150px; height:100px; }
.special_text00{width:230px; }

.special_text00 h3{ font-size:17px; padding-bottom:10px; color:#333}
.special_text00 a{ color:#666666}
.special_text00 a:hover,.special_text00 h3:hover{color:#1877c9; text-decoration:none}
.special_text00 span{ color:#ee5649}

.ztdongt{ width:360px;padding-top: 8px;margin-top: 30px;margin-right: 10px;}
.ztdongt ul{  border-top:none;}
.ztdongt li{ line-height:230%; }
.ztdongt li a{color:#333; font-size:15px;}
.ztdongt li a:hover{color:#1877c9; text-decoration:none}
.zhuanti .zttext{ height:200px;width:330px; *height:140px;*width:268px;box-sizing:border-box;-moz-box-sizing:border-box; /* Firefox */-webkit-box-sizing:border-box; /* Safari */padding:30px; background-color:#FFF; border:1px solid #d5d5d5; }
.cssysa{ padding-bottom:10px; border-bottom:1px dashed #CCC; margin-bottom:10px;}
.cssysa h3{ font-size:16px;}
.cssysa p{ color:#666; font-size:15px;}
.line{border:1px solid #d4dae6; border-top:none; background:url(./static/images/zgf.png) no-repeat right bottom; height:310px;position:relative;}
.line img{ margin-top:25px; padding-left:30px;border-radius:3px;}
.line h2{ font-size:16px; color:#333; padding-top:10px}
.aaaa:hover{ box-shadow:0px 5px 10px 5px #CCC;}
.titlebd{ width:300px;margin: 0 auto;}
.flex-control-nav {width: 100%;position: absolute;bottom: 10px;text-align: center;z-index: 900;}
.flex-control-nav li {margin: 0 4px;display: inline-block;zoom: 1;*display: inline;}
.flex-control-paging li .flex-active {background-color: #187be7!important;}
.flex-control-paging li .round {background: transparent;-webkit-box-shadow: none;-moz-box-shadow: none;-o-box-shadow: none;box-shadow: none;border: 1px solid #187be7;}
.flex-control-paging li .round {width: 9px;height: 9px;display: block;background: #fff;cursor: pointer;text-indent: -9999px;-webkit-border-radius: 20px;-moz-border-radius: 20px;-o-border-radius: 20px;border-radius: 20px; *margin-bottom:2px}
.flex-control-paging li a{ color:#187be7}
.titleh2_01{background:url(./static/images/ht.png) no-repeat 0 0px; display:block; height: 25px; background-color:#fff; float: left; padding-right:5px; padding-left:27px; font-size:20px; font-weight:600; color:#666}
.titleo2{ background:url(./static/images/xgbj.png) no-repeat center; height:20px;}
.titleo1{ border-bottom:1px solid #d4dae6; padding-bottom:8px; height:35px; line-height:35px; padding-top:35px;}
.titleh2{ background:url(./static/images/imgsg.jpg) no-repeat 0 8px; display:block; float: left; padding-right:20px; padding-left:15px; font-size:20px; font-weight:600; color:#666}

.tabul{position: relative;bottom: -10px;}
.tabul li{ float:left; line-height:32px; height:35px; width:160px ; text-align:center;}
.tabul li a{ color:#333; font-size:15px; display:block;background:#fff;border:1px solid #e0e0e0} 
.tabul .on{background:#0074c1; color:#FFF!important;border:1px solid #0074c1; text-decoration:none}
.tabul .sj {width: 27px;height: 15px;overflow: hidden;position:relative;bottom: 0;left: 48%;*left:5%;background: url(./static/images/tab.png) no-repeat;}


.hdbox{ width:330px; height:140px; background-color:#fff;margin: 40px 12px 10px 12px; border-radius:5px; border:1px solid #cfd5df;background-color:#fafafb}
.hdbox:hover{ width:330px; height:140px; background-color:#fff;box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);transform: translate3d(0, -2px, 0);margin-top: 40px; margin-bottom:10px; 
border-radius:5px; background-color:#fafafb}
.hdbox+.hdbox{ margin-left:0;}
.time{ background:url(./static/images/timebj.png) no-repeat center; width:90px; height:90px;margin-top: 25px;margin-left: 20px; text-align:center; position:relative}
.time p{margin-top: 18px;font-size: 18px;color: #3672b8;}
.time span{font-size: 16px;color: #fff;position: relative;top: 10px;}
.time2{ background:url(./static/images/timeb2j.png) no-repeat center; width:90px; height:90px;margin-top: 25px;margin-left: 20px; text-align:center; position:relative}
.time2 p{margin-top: 18px;font-size: 18px;color: #8e8e8e;}
.time2 span{font-size: 16px;color: #fff;position: relative;top: 10px;}
.gray{color: #8e8e8e!important;}
.hdbox .hdtextbox{width:190px; margin-right:20px; margin-top: 25px; }
.hdbox .hdtextbox h2 a{ color:#0855a5; font-size:16px;}
.hdbox .hdtextbox p{ color:#494d51;padding-top:8px; line-height:150%}
.hdbox .hdtextbox span{ color:#757d86; padding-top:8px;}


.cssysa h3{ background:url(./static/images/stub.png) no-repeat 0px 13px; padding-left:10px;}

.sybjt {background: url(./static/images/zz_bg.png) no-repeat bottom;}
.box_con .conbox {position: relative;margin: 0 auto; width: 1050px;}
.box_con { position: relative;}
.box_con .btnl { background: url(./static/images/qianxleft.png) no-repeat center;  left: 20px;}
.box_con .btn {display: block;width: 24px;height: 44px;position: absolute;top: 165px;cursor: pointer;}
.box_con .btnr {background: url(./static/images/qianxr.png) no-repeat center;right: 20px;}


.cgbox{ float:left; width:160px; height:211px; background:url(./static/images/cgbj.png) 0 25px no-repeat;position: relative;transition: 300ms;-webkit-transition: 300ms;-moz-transition: 300ms;-ms-transition: 300ms;-o-transition: 300ms;}
.cgbox span {width: 35px;height: 41px;background: #f90;position: absolute;right: 15px;bottom: 60px;background: url(./static/images/jiao.png) no-repeat;}
.cgbox img {position: absolute;left: 5px;top: 28px;transition: all 0.6s;width: 149px;height: 200px; }
/* .cgbox:hover{ transform: scale(1.1);  background:url(./static/images/cgbj.png) 0 25px no-repeat, url(./static/images/bqimg.png) no-repeat 65px 8px} */
.cgbox+.cgbox{ margin-left:28px;}
.cgbox p{position: absolute;bottom: 10px;text-overflow: unset;white-space: unset;/*text-overflow: ellipsis;white-space: nowrap;*/overflow: hidden;width: 160px;color: #0b57a5;padding-left: 10px;}

.dybox{ width:400px; margin-top:20px;}
.dybox .searchinput {color: #9ecbff;border: 1px solid #579deb;height: 30px;line-height: 30px;width: 260px;padding: 0 10px; background:#1b64b3; margin-right:10px;}
.dybox .searchbtn {background: none repeat scroll 0 0 #3282da;border: 0 none;border-radius: 0;color: #FFFFFF;cursor: pointer;height: 32px;line-height: 32px;width: 80px;text-align: center;font-size: 14px;position: relative;}

.text-gradient {  display: inline-block;color: #666666;/*background: linear-gradient(to right, #10aadd, #0260cb);background-image:url(./static/images/imgsg.jpg) no-repeat 0 8px;*/-webkit-background-clip: text;-webkit-text-fill-color: transparent;padding-left: 0px;}

.xgljbox{ height:60px; background-color:#0b57a5}
.xgljbox a{ color:#cce3f9; padding-right:20px; font-size:15px; line-height:60px;}
.xgljbox a:hover{ color:#FF9}

.gywmbox{ background-color:#f3f5f7; padding:20px 0}
.xglj_con {position: relative;}
.xglj_con .btnl { background: url(./static/images/qianxleft.png) no-repeat center;  left: 20px;}
.xglj_con .btn {display: block;width: 24px;height: 44px;position: absolute;top: 105px;cursor: pointer;}
.xglj_con .btnr {background: url(./static/images/qianxr.png) no-repeat center;right: 20px;}

.gywmbox{ padding:0 10px;margin-top: 30px;padding-bottom: 30px;}
.box1{ width:290px; height:340px; float:left}
.boxbjc_1{ background-color:#d1b792;}
.boxbjc_1:hover{ background-color:#c9a97d;}
.boxbjc_2{ background-color:#9fa3a8;}
.boxbjc_2:hover{ background-color:#909ba9;}
.boxbjc_3{ background-color:#759fd1;}
.boxbjc_3:hover{ background-color:#6290c7;}
.boxbjc_4{ background-color:#b0d1ca;}
.boxbjc_4:hover{ background-color:#8ecdc0;}
.box1 img{ width:290px; height:170px;transition: all 0.6s;}
.box1 img:hover{transform: scale(1.2); }
.imgsize{overflow: hidden;}
.textcss{ height:110px;padding: 30px;    text-overflow: ellipsis;overflow: hidden;}
.textcss a{ color:#fff}
.textcss a:hover{text-decoration:none;color:#fff!important}
.textcss h2{ font-weight:bold; font-size:18px;padding-bottom: 5px;}
.textcss p{ font-size:16px;line-height: 150%;}
.gywmbox .titleo1{ padding-top:20px;}


/*要闻详情*/
.fxd{width: 170px;margin:20px auto; height:27px;height: 30px; line-height:27px;padding-left: 8px;}
.ewm{ text-align:center; margin: 20px auto;}
.ewm img{background: #fff;padding: 5px;}
.text_img{ text-align:center;margin-bottom: 30px;}
.text_img img{ width:700px; background: #fff;padding: 10px;border: 1px #f1f1f1 solid;}
.text_m .title{ width:700px; text-align:center; margin:0 auto; line-height:30px;/*padding-bottom:30px;*/ }
.text_m .title h2{font-weight: bold;font-size: 22px;color: #333;}
.text_m .textnr p{ text-indent:2em;font-size: 16px;line-height: 180%;color: #333;padding-bottom: 15px;text-align: justify;}
.text_m{padding-bottom: 50px;}
.xgzt{margin-bottom: 50px;}
.xgzt h2{background: #f1f1f1;padding-left: 15px;font-size: 20px;color: #000;margin-bottom: 10px; padding:10px 15px}
.xgzt li{ line-height:35px;}
.xgzt li span{ color:#999;}

.xgzt li a{ color:#333; font-size:15px; padding-right:20px; }
.xgzt li a img{ padding-right:8px;}

/*专题项目*/
.xm_box,.zjlb_box{width: 520px;padding: 20px;border: 1px solid #dcdcdc;margin-bottom: 30px;position: relative;} 
.xm_box:hover{ border:1px solid #c2c2c2;box-shadow: 1px 1px 10px #dcdcdc; }
.xm_text a h3{ color:#333;font-size: 16px;}
.xm_text p{ color:#777777; padding-top:15px;}
.xm_box .xm_text .more {width: 68px;height: 24px;background: #9ea6ad;text-align: center;line-height: 24px;font-size: 14px;position: absolute;right: 0;bottom: 0;}
.xm_box .xm_text .more:hover{background: #4b8fcb}
.xm_box .xm_text .more a{ color:#fff}
.xm_text{ width:330px;}


/*专题项目详情*/
.ztleft{ width:780px;  border-right:1px solid #dfdfdf;padding-right: 35px; min-height:500px;  margin-bottom:50px }
.ztleft .title{ text-align:center; margin:0 auto; padding-bottom:20px; line-height: 32px; border-bottom:1px dotted #c0c0c0}
.ztleft .textnr{ text-align:justify;padding-top:20px; }
.ztleft .textnr p{ padding-bottom:20px; font-size:15px;color: #333;line-height: 30px; }
.ztright{ width:340px; height:500px;}
.ztimg img{ float:left; width:333px; height:195px; padding:25px 20px 10px 0}
.titxm{ font-weight:bold; }
.ztright .lbox{ width:320px; float:right; margin-bottom:30px;}
.ztright .lbox ul{ padding:7px 2px; }
.ztright .lbox li{text-overflow : ellipsis;white-space : nowrap;overflow : hidden; line-height:30px; background:url(./static/images/stub.png) no-repeat left;padding-left:10px}
.ztright .lbox li a{ color:#333}
.ztright .lbox .lybjimg4{ background:url(./static/images/lybjimg4.jpg) no-repeat top; height:58px; margin-top:10px;}
.ztright .lbox .lybjimg1{ background:url(./static/images/lybjimg3.jpg) no-repeat top; height:58px; margin-top:10px;}
.ztright .lbox .lybjimg2{ background:url(./static/images/lybjimg1.jpg) no-repeat top; height:58px; margin-top:10px;}
.ztright .lbox .lybjimg3{ background:url(./static/images/lybjimg2.jpg) no-repeat top; height:58px; margin-top:10px;}
.ztright .lbox .e-lybjimg4{ background:url(./static/images/e-lybjimg4.jpg) no-repeat top; height:58px; margin-top:10px;}
.ztright .lbox .e-lybjimg1{ background:url(./static/images/e-lybjimg2.jpg) no-repeat top; height:58px; margin-top:10px;}
.ztright .lbox .e-lybjimg2{ background:url(./static/images/e-lybjimg1.jpg) no-repeat top; height:58px; margin-top:10px;}
.ztright .lbox .e-lybjimg3{ background:url(./static/images/e-lybjimg3.jpg) no-repeat top; height:58px; margin-top:10px;}
.ztright .ejleft{ background:url(./static/images/xg.png) no-repeat top; height:25px; width:320px; }
.ztright .ejleft h2{ background-color:#4b96d6;; height:25px; display:block; width:90px; color:#fff; font-size:16px; text-align:center}
.ztright .lxzj a{background:#F1F1F1; padding:2px 5px; margin-right:10px; margin-top:10px; color:#333;float: left;}
.title1e{border-bottom: 1px solid #d4dae6;margin-bottom:15px;padding-bottom: 11px;text-align: left;}
.title2e{ border-bottom:2px solid #1877c9; width:80px;padding: 0 5px 10px 5px;font-size:18px; font-weight:bold ; color:#515151}
.cgzs{ margin-bottom:30px;}
.cgzs p{ text-indent:2em;font-size:15px;color: #333;line-height: 30px;}
.cgzs .xgyw a{font-size:15px;color: #333;line-height: 30px;background: url(./static/images/stub.png) no-repeat left;padding-left: 10px;}


/*近期活动*/
.jqhd_box{ width: 310px;margin-right: 34px;padding: 20px;border: 1px solid #dcdcdc;margin-bottom: 30px;position: relative;} 
.jqhd_box img{ width:310px; height:182px;}
.jqhd_box:hover{ border:1px solid #c2c2c2;box-shadow: 1px 1px 10px #dcdcdc; }
.jqhd_text{ width:330px;padding-top: 10px; }
.jqhd_text a{ width:330px; display:block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #474747;}
.time_jq{ background-color:#cfcfcf; padding:2px 10px; color:#fff;border-radius: 10px;font-size:12px;}
.time_ks{ background-color:#73b0e7; padding:2px 10px; color:#fff;border-radius: 10px; font-size:12px;}
.jqhd_text li{ line-height:25px;color: #474747;}
.jqhd_text .blue{ color:#1877c9; font-size:15px;}
.jqhd_text .gray1{ color:#474747; font-size:15px;}


/*近期活动详情*/
.ztright .lx{ width:320px; float:right; margin-top:50px; color:#333}
.ztright .lx  li{ padding-bottom:10px;border-bottom: 1px dashed #dcdcdc;margin-bottom: 10px;}
.ztright .lx  li p{ padding-left:23px;}
.ztright .lx .hdsj{ background:url(./static/images/time.png) no-repeat left; padding-left:23px; font-weight:bold;font-size: 15px;}
.ztright .lx .hddd{ background:url(./static/images/add.png) no-repeat left; padding-left:23px;font-weight:bold;font-size: 15px;}
.ztright .lx .hdyt{ background:url(./static/images/yiti.png) no-repeat left; padding-left:23px;font-weight:bold;font-size: 15px;}
.ztright .lx .hdbm{ background:url(./static/images/tel.png) no-repeat left; padding-left:23px;font-weight:bold;font-size: 15px;}

/*合作伙伴*/
.hzhb_box{width:540px; height:200px;margin-right: 34px;border: 1px solid #dcdcdc;margin-bottom: 30px;position: relative; background-color:#fafafb;padding: 20px 0;color: #333;}
.hzhb_box:hover{ border:1px solid #c2c2c2;box-shadow: 1px 1px 10px #dcdcdc; }
.hzhb_box .hzhb_text{padding:10px 20px 0 20px;}
.hzhb_box .logow{ width:150px; height:64px;}
.logocss{width:100%;text-align: center;background-color:#fff;}
.logocss .web{ background-color:#ebf0f4; height:30px; margin-top:10px; line-height:28px; color:#36C}
.addcss{color:#999;font-size: 15px;margin-top:5px;display: block; text-align:right}


/*专家详情*/
.zjleft{ width: 254px;min-height: 530px;text-align: center;padding-top: 25px;background-color: #f1f1f1;}
.zjleft img {width: 150px;height: 185px;}
.paddcss{ padding:50px 20px;box-sizing: border-box;-moz-box-sizing: border-box;-webkit-box-sizing: border-box;}
.zjleft .name{padding: 15px;color: #1877c9;}
.zjleft  .name h3 {font-size: 16px;line-height: 30px;}
.zjleft .channel {padding-bottom: 20px;}
.zjleft .channel li {padding: 0 30px;line-height: 40px;}
.zjleft .channel li a{font-size: 15px;color: #333}
.zjright .expert_content{font-size: 15px;color: #333;line-height: 200%;}
.zjright .expert_content .nametitle{font-size: 16px;font-weight: bold;margin-top: 16px;}
.zjright .expert_content .nametitle2{font-size: 16px;font-weight: bold;}
.zjright{width: 810px;}


/*专家列表*/
.zjlb_box .zjimg{ width:111px ; height:150px}
.zjlb_text a h3{ color:#333;font-size: 17px;}
.zjlb_text p{ color:#777777; padding-top:15px;font-size: 15px; }
.zjlb_text{ width: 380px;}


/*发展经验案例*/
.fzimg img {float: left;width: 300px;height: 176px;}
.fzbg{ margin-top:30px;margin-bottom: 30px;}
.cyname span{ background:url(./static/images/touxiang.png) no-repeat left;padding-left:22px; margin-right:35px;}
.fztext{width:600px;padding-left: 30px;}
.fztext h2{padding-top:10px; font-size:18px;padding-bottom: 10px;}
.fztext p{ padding-top:5px; font-size:15px;}
.fztext p span{ margin-right:30px;}

.tycss{ padding:0 20px;}
.tycss a{color: #3d4145;white-space: nowrap;display: block;float: left;width: 69px;text-align: center; padding-bottom:5px}
.tycss a:hover{ color:#C00}
.xgyw li{ line-height:32px}

/*检索结果*/
.paddcss .list ul li .subtitle{color:#008000;padding: 3px 0;}
.paddcss .list ul li{ padding:25px 0;border-bottom:1px dotted #ccc}
.paddcss .title_ad a{    color: #095795;font-size: 17px; text-decoration:none;}
.paddcss .list .desc{color: #474747;}
.paddcss .title_ad span{color: #474747;}

.ssjl{ background:#f1f1f1;color: #616b74;line-height: 37px; text-align:right; padding-right:20px;margin-bottom:10px;}
.imp{ color:#F00!important}

/*加入我们*/
.zp_leftimg,.leftimg-170{background:url(./static/images/jrimg.jpg) no-repeat top; width:250px;}
.zp_leftimg_e,.leftimg-172{background:url(./static/images/e_jrimg.jpg) no-repeat top; width:250px;}

/*文章详情*/
.img-size-auto,.text_m .textnr img{/*width:auto !important;height:auto !important;*/ max-height: 480px;}

/*新增样式*/
.wzflbox{ text-align:center;padding:20px 0; border-bottom:1px dotted #ccc;margin-bottom: 20px;color: #666;} 
.wzflbox span{ padding-right:20px;}


/*研究成果*/
.textb2 .article img{ width:131px; height:80px}
.textb2 .article{ padding-top:40px;border-bottom: dotted 1px #CCC; padding-bottom:40px; }
.textb2 .article .text{width:720px;}
.textb2 .article .text h3{ font-size:17px;}
.textb2 .article .text span{ color:#666; font-size:13px;}
.textb2 .article .text p{ text-align:justify; color:#7d7d7d; padding-top:5px;}

.ma_right .btn{color: #fff;background-color: #4180c8; border:none;font-size: 16px;padding: 10px 20px;border-radius: 5px;}
.ma_right .btn:hover{background-color: #256cbe;}
.text_b{ padding-bottom:30px;}
.cg_leftimg,.leftimg-212{ background:url(./static/images/cgimg.jpg) no-repeat top; width:250px;}
.cg_leftimg_e,.leftimg-213{ background:url(./static/images/e_cgimg.jpg) no-repeat top; width:250px;}
.leftimg-216{ background:url(./static/images/addressingpoverty.jpg) no-repeat top; width:250px;}
.jp_leftimg{ width:250px;}

.leftimg-217{ background:url(./static/images/addressingpoverty_e.jpg) no-repeat top; width:250px;}
.jp_leftimg_e{ width:250px;}
/*首页*/
.report { margin-top:30px;} 
.report li{ float:left; background-color:#759fd1; border-radius:5px}
.report li+li{ margin-left:42px;}
.report-li1:hover{ background:url(./static/images/report1bj.gif) no-repeat center center}
.report-li2:hover{ background:url(./static/images/report2bj.gif) no-repeat center center}
.report-li3:hover{ background:url(./static/images/report3bj.gif) no-repeat center center}
.report-li4:hover{ background:url(./static/images/report4bj.gif) no-repeat center center}
/*4.11新增*/
/*.more_zd{position: absolute;right: 0;bottom: 0px; color:#999;}*/
.more_zd{position: relative;bottom: -10px;color: #999;float: right;}
.more_zd:hover{text-decoration:none;color: #1877c9;}
/*.tabul {right: 60px;}*/
.left-nav .navli{ text-align:center; font-size:16px;line-height: 35px;margin: 10px 0;}
.left-nav .navli .navleft{ color:#445463}
.left-nav .navli .navleft:hover{ background:#e8edf2; display:block}


/*相关链接*/
.hzhb_box{background-color: #fff;}
.hzhb_text{background-color: #fafafb;}
.trunc {text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.w540 {width: 540px;}
.w500 {width: 500px;}
.w400 {width: 400px;}
.window-mask{left: 0; top: 0; width: 100%; height: 100%; z-index: 290; position: fixed; display: none; background-color: #fff; opacity: 0.5; filter: alpha(opacity=50);}
.window-close{ width: 16px;height: 16px;background: url(./static/images/sd_40.png) no-repeat center center;cursor: pointer;position: absolute;z-index: 310;top: 2px;right: 2px;}
.partner-detail {height:auto;margin:auto;top: calc(50% - 175px);left: calc(50% - 290px);z-index:300; position:fixed;display:none;}
.pro-detail {width:430px;line-height:25px;position:relative;float:left;}
.pro-sub {color:#000;}
.ml70 {margin-left:70px;}

/*专家详情*/
.nametitle2{ color:#0855a5;}
.nametitle{ color:#0855a5;}

/*2019可持续发展论坛*/
.logo_box{ height:90px; margin-top:10px;}
.logo_new{ padding-bottom:20px; border-bottom:1px solid #cbcbcb; text-align: center; margin:10px 0; }
.cv_img img{ background-size:100%; width:675; height:275px;padding: 25px 20px 10px 0;}
.cv_text{   text-align: justify;padding-top: 20px;}
.cv_text p {padding-bottom: 5px; font-size: 15px;color: #333;line-height: 30px; text-indent:2em}
.red{ color:#e32118}
.w555{ width:555px;}
.w267{ width:270px; color:#333; box-sizing:border-box}
.w491{ width:491px;}
.w206{ width:206px;}
.yc_box{ margin-top:35px;margin-bottom: 10px;}

.ycimg1{ background:url(./static/images/yc.jpg) no-repeat center; width:555px; height:54px; line-height:54px;}
.ycimg2{ background:url(./static/images/cy1.jpg) no-repeat center; width:270px; height:54px; line-height:54px;background-size:100%}
.ycimg3{ background:url(./static/images/cy2.jpg) no-repeat center; width:270px; height:54px; line-height:54px; background-size:100%}
.btn_yc{ display:block}
.yctext{ text-align:center; color:#fff; font-size:17px;}

.btn_yc{ margin-top:13px}
.mt20{ margin-top:20px}

.cg_box_new{ border:1px solid #dbe2e8; height:130px;}
.cg_box_new img{ width:91px; height:91px; border-radius:100px;}
.cg_box_new p{ width:130px;margin-top: 25px;}
.p15{ padding:20px 15px 15px 15px }

.jumpBtn {width:100%;bottom: 25px;text-align: right;height:7px;right: 10px;position: relative;}
.jumpBtn ul {margin-bottom: 0px;padding: 0px;}
.jumpBtn ul li {width: 7px;height: 7px;border-radius: 50%;display: inline-block;background-color: white;opacity: 0.9;margin-left: 8px;}


.fybj_box{ background:url(./static/images/picture1.jpg) no-repeat bottom; height:323px;}
.fybj_img{ background:url(./static/images/iconf.png) no-repeat center; height:23px; width:93px; line-height:23px; color:#fff;}
.fybj_img span{ padding-left:15px;}
.fy_more a{ line-height:23px; color:#acacac; font-size:14px; }
.fy_more a:hover{ color:#06C}
.jb_cj{ width:555px; padding:20px 0; color:#acacac;text-overflow : ellipsis;white-space : nowrap;overflow : hidden; }
.jb_cj a{padding:0px 20px; font-size:16px; color:#333; border-right: 1px solid #000;}

.viewpoint_img{ background:url(./static/images/icong.png) no-repeat center; height:23px; width:63px; line-height:23px; color:#fff;}
.viewpoint_img span{ padding-left:15px;}
.viewpoint_btn a{ padding-left:8px}
.viewpoint_text{ padding:15px 20px;}
.viewpoint_text li a{ display:block; line-height:37px; font-size:15px; color:#333;text-overflow : ellipsis;white-space : nowrap;overflow : hidden;}
.viewpoint_text .number{ background:url(./static/images/iconx.png) no-repeat left; padding-left:13px; padding-right:13px; font-weight:600; font-family:Arial, Helvetica, sans-serif; font-size:16px}

.media_text{border: 1px solid #dbe2e8; height:115px; padding:20px; box-sizing:border-box; margin:10px 0;margin-right: 50px;}
.media_text:nth-child(2n){margin-right: 0;}
.media_text h2{ font-size: 18px; color:#333; padding-bottom:8px;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;height: 19px;margin-bottom: 4px;}
.media_text p{ color:#7a7979}

.media_text:hover{ border: 1px solid #269bf5;box-shadow: 0 0 20px rgba(0, 198, 226, 0.5);}
.picture_box img{ width:270px; height:165px;}
.picture_box ul li{ float:left}
.picture_li+.picture_li{margin-left:26px;}

.switchleft{ background:url(./static/images/leftimg.png) no-repeat center; position:relative;  width:57px;height:116px;float: left;left: -70px;z-index: 1000;top:-140px}
.switchright{ background:url(./static/images/rightimg.png) no-repeat center;  position:relative;  width:57px;height:116px;float: right;right: -70px;z-index: 1000;top:-140px}
.picture_box{ padding-bottom:50px;}
.picture_box ul{ position:absolute}
.w555_bj{ background:url(./static/images/hx_bj.jpg) no-repeat;}






/*评论*/
.block-cont-gw {
    padding: 13px 0 11px;
    border-bottom: 1px dashed #e5e5e5;
}
.wrap-user-gw {
    height: 24px;
    line-height: 16px;
    padding: 1px 0 0;
}

.user-time-gw {
    float: right;
    padding: 5px 0 0;
    font-family: Arial;
    color: silver;
}
.user-address-gw {
    color: #dbdbdb;
}
.user-name-gw {
    display: inline-block;
    padding: 5px 7px 0 0;
    cursor: default;
}
.wrap-issue-gw {
    padding: 10px 0 0;
}
.issue-wrap-gw {
    line-height: 22px;
    font-size: 14px;
}
 .block-cont-gw {
    padding: 13px 0 11px;
    border-bottom: 1px dashed #e5e5e5;
	padding-left: 10px;
}

.title-name-gw{
	 color:#1877c9;
	 font-size:16px;
	 line-height: 30px;
	 font-weight: bold;
	}
.title-name-bg input {
    width: 100px;
    height: 30px;
    border: none;
    cursor: pointer;
    background:#1877c9;
    color: white;
    border-radius: 2px;
    
    transition: all ease 0.4s;
    font-size: 16px;
}
.title-name-bg input:hover {
    filter: alpha(opaciyt:70);
    opacity: 0.7;
}
.title-name-bg{
	background: #f5f8fd;
    padding-left: 10px;
	margin-bottom: 15px;
}

.block-cont-gw:last-child{
	margin-bottom:50px;
	}





/*------弹出窗口--------*/
.tc-header{width:100%;text-align:center;height:30px;font-size:24px;line-height:30px;}
.tc{width:500px;position:fixed;border:#ebebeb solid 1px;top:35%;left:50%;background:#ffffff;box-shadow:0px 0px 20px #616168;z-index:9999;margin-left:-250px;margin-top:-140px;}
.tc-title{
	position: relative;
	width: auto;
	height: 39px;
	padding: 0 46px 0 16px;
	margin: 0;
	border-bottom: 1px solid #d9d9d9;
	font-size: 16px;
	line-height: 39px;
	white-space: nowrap;
	background-color: #e6e6e6;}

.tc-title span{position:absolute;font-size:12px;right:-20px;top:-30px;background:#ffffff;border:#ebebeb solid 1px;width:40px;height:40px;border-radius:20px;}
.tc-title span a{display:block;}
.tc-input-content{margin-top:20px;}
.tc-input {overflow:hidden;margin:0px 0px 10px 0px;margin-left: 10px;}
.tc-input label{float:left;width:90px;padding-right:10px;text-align:right;line-height:35px;height:35px;font-size:14px;}
.tc-input input.list-input{float:left;line-height:35px;height:35px;width:350px;border:1px solid #dcdcdc;text-indent:5px;}
.tc-button{width:50%;margin:20px auto 40px auto;line-height:40px;font-size:14px;border:#ebebeb 1px solid;text-align:center;border: none;cursor: pointer;background: #1877c9;color: white;border-radius: 2px;transition: all ease 0.4s;font-size: 16px;float: ;}
.tc-button a{display:block; color:#fff}
.tc-bg{width:100%;height:100%;position:fixed;top:0px;left:0px;background:#ebebeb;filter:alpha(opacity=30);-moz-opacity:0.3;-khtml-opacity:0.3;opacity:0.3;display:none;}
.dialog-close {
    display: inline-block;
    vertical-align: middle;
    background-image: url("./static/images/icons-dialog.png");
    background-repeat: no-repeat;
    position: absolute;
    right: 16px;
    top: 12px;
    width: 14px;
    height: 14px;
    padding: 0;
    margin: 0;
    background-position: 0 -135px;
    cursor: pointer;
}
.dialog-background {
  
    position: fixed;
    left: 0;
    top: 0;
    z-index: 990;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.5);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#88000000,endColorstr=#88000000);
}
.rl_exp_input{
	width: 350px;
	border: 1px solid #dcdcdc;
	height:100px;
	}

.list_box_in{
	background-color: #f5f8fd;
	padding:5px 15px;
	margin-top:10px;
	margin-bottom: 5px;
	}

/*新增*/
.datevideo{font-size:13px; color:#999999;}
.rebox{position: relative; width: 310px;}
.videoimg{position:absolute; width: 30px; height: 30px; background: url(./static/images/video.png) no-repeat left center; bottom:5px; left:10px;background-size:20px;}
.videoimg2{position:absolute; width: 30px; height: 30px; background: url(./static/images/video.png) no-repeat left center; bottom:10px; left:10px;}
.figure_caption{background: linear-gradient(0deg,rgba(0,0,0,.5),rgba(0,0,0,.3) 25.5%,rgba(0,0,0,.08) 70%,rgba(0,0,0,0));position: absolute;right: 0;bottom: 0;left: 0;width:auto;height: 36px;overflow: hidden;}
.figure_caption2{background: linear-gradient(0deg,rgba(0,0,0,.5),rgba(0,0,0,.3) 25.5%,rgba(0,0,0,.08) 70%,rgba(0,0,0,0));position: absolute;right: 0;bottom: 0;left: 0;width:780px;height: 36px;overflow: hidden;}
.mainh3{font-weight:bold; padding:0 0 20px 0; font-size:18px;}
.date_v{font-size:14px; color:#999999; }
.title_v{font-size:26px;line-height: 135%; font-weight: bold; padding:15px 0px 25px 0;}
.right_text{width:320px;margin: 20px 0 0 0;}
.left_video{margin:0 0 50px 0;}
.title_p{font-size: 14px; color:#474747; padding:0 0 45px 0;}
.icon{display: block; width: 30px; height: 30px; margin-right: 15px;}
.icon1{background: url(./static/images/icon1.png) no-repeat center;}
.icon2{background: url(./static/images/icon2.png) no-repeat center;}
.icon3{background: url(./static/images/icon3.png) no-repeat center;}
.icon4{background: url(./static/images/icon4.png) no-repeat center;}
.icon5{background: url(./static/images/icon5.png) no-repeat center;}

.icon1:hover{background: url(./static/images/icon1_hover.png) no-repeat center;}
.icon2:hover{background: url(./static/images/icon2_hover.png) no-repeat center;}
.icon3:hover{background: url(./static/images/icon3_hover.png) no-repeat center;}
.icon4:hover{background: url(./static/images/icon4_hover.png) no-repeat center;}
.icon5:hover{background: url(./static/images/icon5_hover.png) no-repeat center;}
.vbox{position:absolute;  overflow: hidden;bottom:17px; right:-450px; }

.v1{background: url(./static/images/voice.png) no-repeat left center;width:17px; height: 15px; }
.v2{background: url(./static/images/q1.png) no-repeat left center;width:17px; height: 14px;margin:0 15px; }
.v3{background: url(./static/images/q2.png) no-repeat left center;width:15px; height: 12px; }


</style>
